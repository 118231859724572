
<template>
  <layout>
    <v-card>
        <v-card-title>
            <span class="title font-weight-light">รายการแจ้งงาน</span>
        </v-card-title>
    </v-card>
    <Confirm ref="confirm"></Confirm>
    <v-card class="my-3 pb-15">
        <v-card-title>
          <v-icon
                small
                left
            >
                mdi-book-open-variant
            </v-icon>
            <span class="subtitle-1">ข้อมูล</span>
        </v-card-title>
        <v-divider class="mx-4" />
        
        <v-container fluid class="body-2">
            <v-row no-gutters>
                <v-col cols="6">
                    <v-text-field
                        prepend-inner-icon="mdi-magnify"
                        label="ค้นหาจากหัวข้อเรื่อง"
                        outlined
                        dense
                        v-model="keywordTopic"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="3" class="mr-4">
                <v-select
                    v-model="jobType"
                    :items="jobTypeItems"
                    item-text="type_name"
                    item-value="id"
                    label="ค้นหาจากประเภทงาน"
                    dense
                    outlined
                    clearable
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                    v-model="jobStatus"
                    :items="jobStatusItems"
                    item-text="status_name"
                    item-value="id"
                    label="ค้นหาจากสถานะ"
                    dense
                    outlined
                    clearable
                ></v-select>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="3" class="mr-4">
                <v-menu
                    v-model="menuStartJobDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="startDate"
                          label="ค้นหาจากวันที่แจ้ง"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          outlined
                          dense
                          v-bind="attrs"
                          v-on="on"
                          clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="startDate"
                        locale="th-th"
                        @input="menuJobDate = false"
                    ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="3">
                <v-menu
                    v-model="menuEndJobDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="endDate"
                          label="ถึงวันที่แจ้ง"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          outlined
                          dense
                          v-bind="attrs"
                          v-on="on"
                          clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="endDate"
                        locale="th-th"
                        @input="menuJobDate = false"
                    ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-btn color="success" @click="search()">ค้นหา</v-btn>
            
            <v-divider class="my-6" />

            <!-- <v-btn depressed color="primary" to="/job/request" class="mb-2">
                + แจ้งงาน
            </v-btn> -->
            <v-row>
                <v-col>
                    <v-card outlined>
                        <v-data-table
                            :headers="headers"  
                            :items="jobData"
                            disable-sort
                            :loading="tableLoading"
                            :options.sync="tableOptions"
                            @update:options="updateOptions"
                            :server-items-length="totalData"
                            :footer-props="{
                              'items-per-page-options':[5, 10, 20, 40]
                            }"
                        >
                            <template v-slot:item.topic="{ item }">
                              <a :href="'/job/' + item.id">{{ item.topic }}</a>
                            </template>
                            <template v-slot:item.status_name="{ item }">
                              <v-chip
                                v-if="item.job_status == 4"
                                color="green"
                                text-color="white"
                              >
                                {{ item.status_name }}
                              </v-chip>
                              <span v-else>{{ item.status_name }}</span>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <v-btn v-if="item.job_status < 5" @click="openEditJob(item)" depressed class="mr-2 orange darken-2 white--text">
                                    แก้ไข
                                </v-btn>

                                <v-btn v-if="item.job_status < 4" @click="deleteData(item.id)" depressed class="mr-2 red darken-2 white--text">
                                    ยกเลิก
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
            
        </v-container>
    </v-card>
  </layout>
</template>
<script>
import Api from "@/api/Api";

export default {
    data: () => ({
      storeUser: null,
        headers: [
            {
                text: "เรื่อง",
                value: "topic",
                // class: "text-subtitle-1 font-weight-bold grey--text text--darken-4"
            },
            {
                text: "แจ้งวันที่",
                value: "job_date"
            },
            {
                text: "ประเภทงาน",
                value: "type_name"
            },
            {
                text: "สถานะ",
                value: "status_name"
            },
            {
                text: "จัดการ",
                value: "actions",
                align: "center"
            }
        ],
        jobData: [],
        keywordTopic: '',
        tableLoading: false,
        tableOptions: {},
        mode: 'all',
        totalData: 0,
        menuStartJobDate: false,
        menuEndJobDate: false,
        jobType: null,
        jobTypeItems: [],
        jobStatus: null,
        jobStatusItems: [],
        startDate: null,
        endDate: null
    }),
    created() {
      this.storeUser = this.$store.getters.user
    },
    async mounted() {
      this.$store.commit('setLoading', true)
      await this.getJobType();
      await this.getJobStatus();
      this.$store.commit('setLoading', false)
    },
    methods: {
      async openEditJob(item) {
        console.log(item)
        var jobRootId = String(item.job_root_type_id)
        console.log(jobRootId)
        switch(jobRootId) {
          case "1":
            this.$router.push('/job/request/edit/' + item.id)
            break;
          // case "1":
          //   this.$router.push('/job/request/edit/' + item.id)
          //   break;
          case "3":
            this.$router.push('/job/project/request/edit/' + item.id)
            break;
        }
        
      },
      async updateOptions(options){
        console.log("options", options)
        if(this.mode == "all"){
          this.getData()
        }else{
          this.search()
        }
        
      },
      async search() {
        try {
          this.tableLoading = true

          const req = {
            params: {
              request_by: this.storeUser.id,
              topic: this.keywordTopic,
              job_type: this.jobType,
              job_status: this.jobStatus,
              start_date: this.startDate,
              end_date: this.endDate,
              page: this.tableOptions.page,
              per_page: this.tableOptions.itemsPerPage
            }

          }
          const res = (await Api().get("jobowner/search", req)).data;

          console.log('data', res);

          if (res.status == 'success') {
            this.jobData = res.data
            this.totalData = res.total
            this.tableLoading = false
          } else {
            console.log(res.data);
            this.tableLoading = false
          }
        } catch (err) {
          console.log(err.message);
          this.tableLoading = false
        }
      },
      async getJobType() {
        try {
          const res = (await Api().get("jobtype")).data;
          //const dd = JSON.parse(res);

          if (res.status == 'success') {
            this.jobTypeItems = res.data;
          } else {
            console.log(res.data);
          }
        } catch (err) {
          console.log(err.message);
        }
      },
      async getJobStatus() {
        try {
          const res = (await Api().get("jobstatus")).data;
          //const dd = JSON.parse(res);

          if (res.status == 'success') {
            this.jobStatusItems = res.data;
          } else {
            console.log(res.data);
          }
        } catch (err) {
          console.log(err.message);
        }
      },
      async getData() {
        try {
          this.tableLoading = true

          const req = {
            params: {
              request_by: this.storeUser.id,
              page: this.tableOptions.page,
              per_page: this.tableOptions.itemsPerPage
            }

          }
          const res = (await Api().get("jobowner", req)).data;

          console.log('data', res);

          if (res.status == 'success') {
            this.jobData = res.data
            this.totalData = res.total
            this.tableLoading = false
          } else {
            console.log(res.data);
            this.tableLoading = false
          }
        } catch (err) {
          console.log(err.message);
          this.tableLoading = false
        }
      },
      async deleteData(id){
        if (await this.$refs.confirm.open('ยืนยัน', 'ต้องการลบข้อมูลหรือไม่', { color: 'primary', noconfirm: false })) {
          console.log('cancel job', id)
          // this.$store.commit('setLoading', true)

          // const req = {}

          // let res = (await Api().post("/staff/del/" + id, req)).data;

          // if (res.status == 'success') {
          //   this.$store.commit('setLoading', false)
          //   await this.getData();
          // }else{
          //   this.$store.commit('setLoading', false)
          // }
        }
      },
    }
};
</script>